<template>
  <table
      v-if="dataLoaded"
      :key="updateKey"
      id="table-body"
      class="table-wrapper"
  >
    <tr
        class="table-head"
        @click="columnAction('closeAll')"
        style="border-bottom: 1px solid #e6e8ec !important"
    >
      <th
          class="table-head-item"
          v-for="(field, index) in sortedFields"
          :key="field.fieldValue"
          @click="columnAction(index, field)"
      >
        <v-row style="display: flex; flex-wrap: nowrap">
          <p
              :class="{
              'bold-weight': sortCounters[index][field.jsonValue] !== 1,
              'normal-weight': sortCounters[index][field.jsonValue] === 1,
            }"
              class="column-label"
          >
            {{ field.fieldValue }}
          </p>
          <img
              v-if="
              field.jsonValue !== 'files' &&
              field.jsonValue !== 'floor' &&
              //field.jsonValue !== 'status' &&
              field.jsonValue !== 'unitSize' &&
              field.jsonValue !== 'client' &&
              field.jsonValue !== 'description' &&
              field.jsonValue !== 'price'
            "
              src="../../assets/images/direction=up.svg"
              style="width: 16px; height: 16px"
              alt=""
              v-bind:class="{
              up: sortCounters[index][field.jsonValue] === 2,
              down: sortCounters[index][field.jsonValue] === 3,
              hidden: sortCounters[index][field.jsonValue] === 1,
            }"
          />
        </v-row>
      </th>
    </tr>
    <div></div>
    <tr
        v-bind:class="{
        'table-body-row': !object.recentlyAdded,
        'table-body-row recently-added': object.recentlyAdded,
      }"
        v-for="(object, intex) in sortedDataSet"
        :key="object._id"
    >
      <td
          class="table-body-item"
          :style="field.style"
          v-for="(field, index) in sortedFields"
          :key="field.jsonValue"
          @click="(event) => columnClick(index, field, object, event)"
      >
        <div v-if="field.jsonValue === 'firstName'">
          <v-row>
            <p class="listing-item">{{ object.firstName }}</p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'lastName'">
          <v-row>
            <p class="listing-item">
              {{ object.lastName }}
            </p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'paidBrokerageFee'">
          <v-row>
            <p class="listing-item">
              {{ $nMapper.price(object.paidBrokerageFee, "€") }}
            </p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'unpaidBrokerageFee'">
          <v-row>
            <p class="listing-item">
              {{ $nMapper.price(object.unpaidBrokerageFee, "€") }}
            </p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'totalBrokerageFee'">
          <v-row>
            <p class="listing-item">
              {{ $nMapper.price(object.totalBrokerageFee, "€") }}
            </p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'paid'">
          <v-row>
            <p class="listing-item">
              {{ $nMapper.price(object.paid, "") }}
            </p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'unpaid' && invoicesView">
          <v-row>
            <p class="listing-item">
              {{ $nMapper.price(object.unpaid, "") }}
            </p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'sum'">
          <v-row>
            <p class="listing-item" v-if="!object.totalSum">
              {{ $nMapper.price(object.sum, "") }}
            </p>
            <div style="display: flex; gap: 4px; align-items: flex-end; white-space: nowrap" v-else>
              <p class="content-normal">{{ $nMapper.price(object.sum, "") }} / </p>
              <p class="content-small" style="line-height: unset">{{ $nMapper.price(object.totalSum, "") }}</p>
            </div>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'sumVAT'">
          <v-row>
            <p class="listing-item" v-if="!object.totalSumVAT">
              {{ $nMapper.price(object.sumVAT, "") }}
            </p>
            <div style="display: flex; gap: 4px; align-items: flex-end; white-space: nowrap" v-else>
              <p class="content-normal">{{ $nMapper.price(object.sumVAT, "") }} / </p>
              <p class="content-small" style="line-height: unset">{{ $nMapper.price(object.totalSumVAT, "") }}</p>
            </div>
          </v-row>
        </div>
        <div v-else-if="customerView && field.jsonValue === 'phoneNumber'">
          <v-row>
            <p
                v-if="object.phoneNumbers[0]"
                class="listing-item underlined"
                style="padding-right: 8px"
                @click.stop="selectPhone(object.phoneNumbers[0].phoneNumber)"
            >
              {{ object.phoneNumbers[0].phoneNumber }}
            </p>
            <CopyButton
                :toast-message="'PhoneNumberCopied'"
                :copy-text="object.phoneNumbers[0].phoneNumber"
                v-if="object.phoneNumbers[0]"
            />
            <p
                v-if="!object.phoneNumbers[0]"
                class="listing-item"
                style="width: 80px; height: 20px"
            >
              -
            </p>
            <v-menu :close-on-content-click="false" offset-y bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="outputs-wrapper">
                  <p
                      v-if="object.phoneNumbers[1]"
                      class="additional-info listing-item"
                  >
                    {{ numberOfPhones(object) }}
                  </p>
                </div>
              </template>
              <div class="outputs-modal">
                <v-row
                    v-for="object in object.phoneNumbers.slice(1)"
                    :key="object.id"
                    class="output-row"
                >
                  <p
                      class="phone-number-text underlined"
                      @click="selectPhone(object.phoneNumber)"
                  >
                    {{ object.phoneNumber }}
                  </p>
                  <CopyButton
                      :toast-message="'PhoneNumberCopied'"
                      :copy-text="object.phoneNumber"
                  />
                </v-row>
              </div>
            </v-menu>
          </v-row>
        </div>
        <div v-else-if="customerView && field.jsonValue === 'email'">
          <v-row>
            <p
                v-if="object.emails[0]"
                class="listing-item underlined"
                style="padding-right: 8px"
                @click.stop="selectEmail(object.emails[0].email)"
            >
              {{ object.emails[0].email }}
            </p>
            <CopyButton
                :toast-message="'EmailCopied'"
                :copy-text="object.emails[0].email"
                v-if="object.emails[0]"
            />
            <p
                v-if="!object.emails[0]"
                class="listing-item"
                style="width: 80px; height: 20px"
            >
              -
            </p>
            <v-menu
                :close-on-content-click="false"
                :nudge-width="205"
                offset-y
                bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="outputs-wrapper">
                  <p
                      v-if="object.emails[1]"
                      class="additional-info listing-item"
                  >
                    {{ numberOfEmails(object) }}
                  </p>
                </div>
              </template>
              <div class="outputs-modal">
                <v-row
                    v-for="object in object.emails.slice(1)"
                    @click.stop="selectEmail(object.email)"
                    :key="object.id"
                    class="output-row underlined"
                >
                  {{ object.email }}
                  <CopyButton
                      :toast-message="'EmailCopied'"
                      :copy-text="object.email"
                  />
                </v-row>
              </div>
            </v-menu>
          </v-row>
        </div>
        <div v-else-if="customerView && field.jsonValue === 'organization'">
          <v-row>
            <p
                v-if="object.organizations[0]"
                class="listing-item underlined"
                style="padding-right: 8px"
            >
              {{ object.organizations[0].organization }}
            </p>
            <CopyButton
                :toast-message="'OrganizationDataCopied'"
                :copy-text="selectOrganization(object.organizations[0])"
                v-if="object.organizations[0]"
            />
            <p
                v-if="!object.organizations[0]"
                class="listing-item"
                style="width: 80px; height: 20px"
            >
              -
            </p>
            <v-menu
                :close-on-content-click="true"
                :nudge-width="205"
                offset-y
                bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="outputs-wrapper">
                  <p
                      v-if="object.organizations[1]"
                      class="additional-info listing-item"
                  >
                    {{ numberOfOrganizations(object) }}
                  </p>
                </div>
              </template>
              <div class="outputs-modal">
                <v-row
                    v-for="org in object.organizations.slice(1)"
                    :key="org.id"
                    class="output-row org-link"
                    @click="openCustomer(object, org.reg_code, org)"
                >
                  {{ org.organization }}
                  <CopyButton
                      :toast-message="'OrganizationDataCopied'"
                      :copy-text="selectOrganization(org)"
                  />
                </v-row>
              </div>
            </v-menu>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'invoiceType'">
          <v-row>
            <p
                class="listing-item"
                style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              "
            >
              {{ formatInvoiceType(object["type"]) }}
            </p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'invoiceProject'">
          <v-row>
            <p
                class="listing-item"
                style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              "
            >
              {{
                object["project"] && object["project"]["name"]
                    ? object["project"]["name"]
                    : "-"
              }}
            </p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'broker' && !invoicesView">
          <v-row>
            <p
                class="listing-item"
                style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              "
            >
              {{
                object["broker"]
                    ? object["broker"]["name"]
                    : object["creator"]["name"]
              }}
            </p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'broker' && invoicesView">
          <div style="display: flex; justify-content: space-between; gap: 8px;">
            <p
                class="listing-item"
                style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 100%;
              "
            >
              {{
                object["broker"]
                    ? object["broker"]["name"]
                    : object["creator"]["name"]
              }}
            </p>
            <template v-if="object['sharedBrokers'] && object['sharedBrokers'].length > 0">
              <img :src="require('@/assets/images/additional_users.svg')" width="20" height="20" alt=""/>
            </template>
          </div>
        </div>
        <div v-else-if="field.jsonValue === 'birthday'">
          <v-row>
            <p class="listing-item">
              {{ formatDateWithoutYear(object[field.jsonValue]) }}
            </p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'tags'">
          <v-row style="flex-wrap: nowrap">
            <div
                v-if="object.tags && object.tags.propertyOwner['active']"
                style="background-color: #b5e7cf"
                class="tag-container-table"
            >
              <p class="tag-name">{{ $t("PO") }}</p>
            </div>
            <div
                v-if="object.tags && object.tags.landlord['active']"
                style="background-color: #b8d8ff"
                class="tag-container-table"
            >
              <p class="tag-name">{{ $t("L") }}</p>
            </div>
            <div
                v-if="object.tags && object.tags.buyer['active']"
                style="background-color: #fff2b3"
                class="tag-container-table"
            >
              <p class="tag-name">{{ $t("B") }}</p>
            </div>
            <div
                v-if="object.tags && object.tags.tenant['active']"
                style="background-color: #fcbae2"
                class="tag-container-table"
            >
              <p class="tag-name">{{ $t("T").toUpperCase() }}</p>
            </div>
            <div
                v-if="object.tags && object.tags.other['active']"
                style="background-color: #c3c4c6"
                class="tag-container-table"
            >
              <p class="tag-name">{{ $t("O") }}</p>
            </div>

            <div
                v-if="
                object.tags &&
                object.tags.photographer &&
                object.tags.photographer['active']
              "
                style="background-color: #16afb9"
                class="tag-container-table"
            >
              <p class="tag-name white-tag-text">{{ $t("PH") }}</p>
            </div>
            <div
                v-if="
                object.tags &&
                object.tags.valuator &&
                object.tags.valuator['active']
              "
                style="background-color: #aa086a"
                class="tag-container-table"
            >
              <p class="tag-name white-tag-text">{{ $t("VA") }}</p>
            </div>
            <div
                v-if="
                object.tags &&
                object.tags.notary &&
                object.tags.notary['active']
              "
                style="background-color: #4c8dd9"
                class="tag-container-table"
            >
              <p class="tag-name white-tag-text">{{ $t("NO") }}</p>
            </div>
            <div
                v-if="
                object.tags &&
                object.tags.bank_representative &&
                object.tags.bank_representative['active']
              "
                style="background-color: #b54616"
                class="tag-container-table"
            >
              <p class="tag-name white-tag-text">{{ $t("BA") }}</p>
            </div>
            <div
                v-if="
                object.tags &&
                object.tags.otherInPartner &&
                object.tags.otherInPartner['active']
              "
                style="background-color: #75787a"
                class="tag-container-table"
            >
              <p class="tag-name white-tag-text">{{ $t("O") }}</p>
            </div>

            <p
                v-if="
                !object.tags ||
                (!object.tags.propertyOwner['active'] &&
                  !object.tags.other['active'] &&
                  !object.tags.landlord['active'] &&
                  !object.tags.tenant['active'] &&
                  !object.tags.buyer['active'] &&
                  (!object.tags.photographer ||
                    !object.tags.photographer['active']) &&
                  (!object.tags.valuator || !object.tags.valuator['active']) &&
                  (!object.tags.notary || !object.tags.notary['active']) &&
                  (!object.tags.bank_representative ||
                    !object.tags.bank_representative['active']) &&
                  (!object.tags.otherInPartner ||
                    !object.tags.otherInPartner['active']))
              "
                class="listing-item"
            >
              -
            </p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'interests'">
          <v-row>
            <p v-if="object.interests" class="listing-item">
              {{ numberOfInterests(object) }}
            </p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'projects'">
          <v-row>
            <p v-if="object.projectCounts">
              <span style="font-weight: bold"
              >{{
                  object.projectCounts.active + object.projectCounts.archived
                }}
              </span>
              <span
              >({{ object.projectCounts.active }}/{{
                  object.projectCounts.archived
                }})</span
              >
            </p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'files'">
          <v-row v-if="object.files != '[]' && object.files != ''">
            <img
                src="../../assets/images/attachmentBlack.svg"
                style="width: 15px; height: 15px"
                alt=""
            />
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'firstname'">
          <v-row>
            <p class="listing-item">
              {{ object.firstname }}
            </p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'lastname'">
          <v-row>
            <p class="listing-item">
              {{ object.lastname }}
            </p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'accessLevel'">
          <v-row>
            <p class="listing-item">
              {{ formatRole(object) }}
            </p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'types'">
          <v-row>
            <p class="listing-item-no-ellipsis">
              {{ formatUserType(object) }}
            </p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'group'">
          <v-row>
            <p class="listing-item">
              {{
                formatGroupName(object)
              }}
            </p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'groups'">
          <v-row>
            <p class="listing-item">
              {{ object.groups.length }}
            </p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'LastLogin'">
          <v-row>
            <p class="listing-item">
              {{ formatLoginDate(object.LastLogin) }}
            </p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'userStatus'">
          <v-row>
            <p class="listing-item">{{ object.userStatus }}</p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'unpaid' && !invoicesView">
          <v-row>
            <p class="listing-item">{{ object.unpaid }}</p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'transactionName'">
          <v-row
              style="
              gap: 4px;
              display: flex;
              flex-wrap: nowrap;
              align-content: center;
              justify-items: start;
            "
          >
            <DealTypeContainer :deal-type="object.transactionType"/>

            <p
                style="
                overflow: hidden;
                max-width: 256px;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
                class="listing-item"
            >
              {{ object.transactionName }}
            </p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'creator'">
          <v-row>
            <p class="listing-item hover-option">
              {{ getOwner(object.access.users)}}
            </p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'dealValue'">
          <v-row>
            <p class="listing-item">
              {{ $nMapper.price(object.dealValue, "€") }}
            </p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'brokerageFee'">
          <v-row>
            <p class="listing-item">
              {{ $nMapper.price(object.brokerageFee, "€") }}
            </p>
          </v-row>
        </div>
        <div
            v-else-if="
            field.jsonValue === 'transactionDate' ||
            field.jsonValue === 'createdAt'
          "
        >
          <v-row>
            <p class="listing-item">
              {{ formatDate(object[field.jsonValue]) }}
            </p>
          </v-row>
        </div>
        <div
            v-else-if="
            field.jsonValue === 'paymentDueDate' ||
            field.jsonValue === 'invoiceDate'
          "
        >
          <v-row>
            <p class="listing-item">
              {{ formatDate(object[field.jsonValue]) }}
            </p>
          </v-row>
        </div>
        <div v-else-if="!invoicesView && field.jsonValue === 'status'">
          <v-row>
            <p
                v-bind:class="{
                'paid-label': object.status === 'paid',
                'not-paid-label': object.status === 'not_paid',
              }"
                :id="'statusLabel' + object._id"
                class="statusLabel content-bold"
                @click="openStatusDropdown(object)"
            >
              {{ formatStatus(object.status) }}
            </p>
          </v-row>
        </div>
        <div v-else-if="invoicesView && field.jsonValue === 'status'">
          <StatusDropdown
              :show-additional-info="true"
              :invoice="object"
              @reloadInvoice="$emit('reloadInvoices')"
          />
        </div>
        <div v-else-if="field.jsonValue === 'listings'">
          <v-row>
            <p class="listing-item">{{ object.listings }}</p>
          </v-row>
        </div>
        <div v-else-if="field.jsonValue === 'activeListings'">
          <v-row>
            <p class="listing-item">
              <a style="font-weight: bold">{{ object.activeListings }}</a> /
              <a :name="'activelistingstotal' + intex">
                {{ object.activeListingsTotal }}</a
              >
            </p>
          </v-row>
        </div>
        <div
            v-else-if="field.jsonValue === 'kvActive' && editableIndex !== index"
        >
          <v-row>
            <p class="listing-item">
              <a style="font-weight: bold">{{ object.kvActive }}</a> /
              <a :name="'kvtotal' + intex"> {{ object.kvTotal }}</a>
            </p>
          </v-row>
        </div>
        <div
            v-else-if="
            field.jsonValue === 'kv24Active' && editableIndex !== index
          "
        >
          <v-row>
            <p class="listing-item" :name="'kv24Export' + intex">
              <a :name="'kv24active' + intex" style="font-weight: bold">{{
                  object.kv24Active
                }}</a>
              /
              <a :name="'kv24total' + intex">{{ object.kv24Total }}</a>
            </p>
          </v-row>
        </div>
        <div
            v-else-if="
            field.jsonValue === 'city24Active' && editableIndex !== index
          "
        >
          <v-row>
            <p class="listing-item" :name="'city24Export' + intex">
              <a style="font-weight: bold">{{ object.city24Active }}</a> /
              <a :name="'city24total' + intex">{{ object.city24Total }}</a>
            </p>
          </v-row>
        </div>
        <div
            v-else-if="field.jsonValue === 'wwwActive' && editableIndex !== index"
        >
          <v-row>
            <p class="listing-item">
              <a style="font-weight: bold">{{ object.wwwActive }}</a> /
              <a :name="'wwwtotal' + intex">{{ object.wwwTotal }}</a>
            </p>
          </v-row>
        </div>

        <div
            v-else-if="
            object[field.jsonValue] === 'rented' && editableIndex !== index
          "
            class="statusLabel rented"
        >
          {{ $t("Rented") }}
        </div>
        <div
            v-else-if="
            object[field.jsonValue] === 'sold' && editableIndex !== index
          "
            class="statusLabel rented"
        >
          {{ $t("Sold") }}
        </div>
        <div
            v-else-if="
            object[field.jsonValue] === 'booked' && editableIndex !== index
          "
            class="statusLabel booked"
        >
          {{ $t("Booked") }}
        </div>
        <div
            v-else-if="
            object[field.jsonValue] === 'free' && editableIndex !== index
          "
            class="statusLabel free"
        >
          {{ $t("Free") }}
        </div>
        <p
            v-else-if="
            object[field.jsonValue] &&
            editableIndex !== index &&
            field.jsonValue !== 'client' &&
            roundingDigits === undefined
          "
            class="content-normal normal-field"
        >
          {{ object[field.jsonValue] }}
        </p>
        <p
          v-else-if="
            object[field.jsonValue] &&
            editableIndex !== index &&
            field.jsonValue !== 'client' &&
            roundingDigits !== undefined
          "
          class="content-normal normal-field"
        >
          <span>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ roundValue(object[field.jsonValue]) }}
                </span>
              </template>
              <span>{{  formatTotal(object[field.jsonValue]) }}</span>
            </v-tooltip>
          </span>
        </p>
        <p
          v-else-if="
            field.jsonValue === 'client' &&
            object[field.jsonValue] &&
            editableIndex !== index
          "
            class="content-normal normal-field"
        >
          {{ object.client.customerName }}
        </p>
        <p
            v-else-if="editableIndex !== index"
            class="content-normal normal-field"
        >
          -
        </p>
        <v-select
            @focusin="
            objectEdited = object;
            editedField = field.jsonValue;
          "
            @focusout="saveEditedObject"
            hide-details
            height="20"
            outlined
            dense
            item-text="customerName"
            return-object
            :items="customers"
            v-if="editableIndex === index && field.jsonValue === 'client'"
            v-model="object[field.jsonValue]"
        ></v-select>
        <v-select
            @focusin="
            objectEdited = object;
            editedField = field.jsonValue;
          "
            @focusout="saveEditedObject"
            hide-details
            height="20"
            outlined
            dense
            item-value="value"
            item-text="name"
            :items="statuses"
            :label="$t('Status')"
            v-if="editableIndex === index && field.jsonValue === 'status'"
            v-model="object[field.jsonValue]"
        ></v-select>
        <v-text-field
            @focusin="
            objectEdited = object;
            editedField = field.jsonValue;
          "
            @focusout="saveEditedObject"
            hide-details
            height="20"
            outlined
            dense
            v-if="
            editableIndex === index &&
            field.jsonValue !== 'client' &&
            field.jsonValue !== 'status' &&
            field.jsonValue !== 'kv24Active' &&
            field.jsonValue !== 'kvActive' &&
            field.jsonValue !== 'city24Active' &&
            field.jsonValue !== 'wwwActive'
          "
            v-model="object[field.jsonValue]"
        ></v-text-field>
        <v-text-field
            @focusin="
            objectEdited = object;
            editedField = 'kv24Total';
          "
            @focusout="saveEditedObject"
            type="number"
            hide-details
            height="20"
            :name="'kv24ExportField' + intex"
            :id="'kv24ExportField'"
            outlined
            dense
            v-if="editableIndex === index && field.jsonValue === 'kv24Active'"
            v-model="object['kv24Total']"
        ></v-text-field>
        <v-text-field
            @focusin="
            objectEdited = object;
            editedField = 'kvTotal';
          "
            @focusout="saveEditedObject"
            type="number"
            hide-details
            height="20"
            outlined
            dense
            v-if="editableIndex === index && field.jsonValue === 'kvActive'"
            v-model="object['kvTotal']"
        ></v-text-field>
        <v-text-field
            @focusin="
            objectEdited = object;
            editedField = 'city24Total';
          "
            @focusout="saveEditedObject"
            type="number"
            hide-details
            height="20"
            outlined
            dense
            v-if="editableIndex === index && field.jsonValue === 'city24Active'"
            v-model="object['city24Total']"
        ></v-text-field>
        <v-text-field
            @focusin="
            objectEdited = object;
            editedField = 'wwwTotal';
          "
            @focusout="saveEditedObject"
            type="number"
            hide-details
            height="20"
            outlined
            dense
            v-if="editableIndex === index && field.jsonValue === 'wwwActive'"
            v-model="object['wwwTotal']"
        ></v-text-field>
      </td>
    </tr>
    <BrokerInfoPanel
        v-if="brokerDetailOpened"
        :item="clickedBroker"
        @closed="closeBrokerDetailPanel"
        :position="position"
    />
  </table>
</template>
<script>
import axios from "axios";
import {mapActions, mapGetters} from "vuex";
import ClickOutside from "vue-click-outside";
import moment from "moment";
import DealTypeContainer from "@/components/common/DealTypeContainer";
import BrokerInfoPanel from "@/components/common/BrokerInfoPanel";
import CopyButton from "@/components/common/CopyButton";
import StatusDropdown from "@/components/Invoices/StatusDropdown";

export default {
  name: "Table",
  components: {
    StatusDropdown,
    DealTypeContainer,
    BrokerInfoPanel,
    CopyButton,
    // eslint-disable-next-line no-undef
  },
  directives: {
    ClickOutside,
  },
  props: [
    "fields",
    "dataset",
    "objectId",
    "dealId",
    "customers",
    "listingId",
    "addGap",
    "listingView",
    "customerView",
    "adminView",
    "scorecardView",
    "updateKey",
    "sortAgain",
    "iconUpdater",
    "adminMode",
    "userId",
    "projectType",
    "transactionView",
    "invoicesView",
    "organizationsView",
    "selectedSort",
    "roundingDigits"
  ],
  data() {
    return {
      dataLoaded: false,
      datasetCopy: null,
      brokerDetailOpened: false,
      position: null,
      clickedBroker: null,
      editableIndex: null,
      objectEdited: null,
      exactIndex: 1,
      exactField: null,
      editedField: null,
      boardAlreadyOpened: false,
      contactFirstNameCounter: 1,
      isAscendingFirstName: false,
      originalArr: null,
      sortCounters: [],
      sortedFields: [],
      sortedDataSet: [],
      lastSortedIndex: null,
      lastSortedField: null,
      lastSortedStep: null,
      clickedObject: null,
      personWithoutGroup: [],
    };
  },
  created() {
    this.setupTable();
  },
  mounted() {
    document.addEventListener("click", (event) => {
      if (event.target.id !== "table-body") {
        let tableBodyExists = false;
        event.path.forEach((path) => {
          if (path.id === "table-body") {
            tableBodyExists = true;
          }
        });
        if (!tableBodyExists) {
          this.columnAction("closeAll");
        }
      }
    });
    console.log(this.roundingDigits)
  },
  beforeDestroy() {
    document.removeEventListener("click", null);
  },
  computed: {
    statuses() {
      if (this.projectType === "Sale") {
        return [
          {name: this.$t("Free"), value: "free"},
          {name: this.$t("Booked"), value: "booked"},
          {name: this.$t("Sold"), value: "sold"},
        ];
      } else {
        return [
          {name: this.$t("Free"), value: "free"},
          {name: this.$t("Booked"), value: "booked"},
          {name: this.$t("Rented"), value: "rented"},
        ];
      }
    },

    ...mapGetters(["clientSidepanelStatus", "user"]),
  },
  methods: {
    ...mapActions([
      "setClientSidepanel",
      "changeClientAddModal",
      // "getCustomers",
    ]),
    selectPhone(phoneNumber) {
      window.open(`tel:${phoneNumber}`, "popup");
    },
    selectEmail(email) {
      window.open(`mailto:${email}`, "popup");
    },
    formatDate(transactionDate) {
      if (transactionDate) {
        return moment(transactionDate).format("DD.MM.YYYY");
      } else {
        return "-";
      }
    },
    formatDateWithoutYear(birthdayDate) {
      if (birthdayDate) {
        return moment(birthdayDate).format("DD.MM");
      } else {
        return "-";
      }
    },
    getOwner(users) {
      for (const userId in users) {
        if (users[userId].ownershipType === 'owner') {

          return users[userId].name;
        }
      }
    },
    selectOrganization(org) {
      let copyString = this.$t("CompanyName") + "    " + org.company_name;
      if (org.reg_code) {
        copyString +=
            `\n${this.$t("RegistrationNumber")}` + "    " + org.reg_code;
      }
      if (org.kmkr) {
        copyString += `\n KMKR` + "    " + org.reg_code;
      }
      return copyString;
    },
    formatStatus(status) {
      if (status === "not_paid") {
        return this.$t("NotPaid");
      } else if (status === "paid") {
        return this.$t("Paid");
      } else return;
    },
    formatRole(user) {
      if (user.isAdmin) {
        return this.$t("Admin");
      } else if (user.isGroupAdmin) {
        return this.$t("GroupAdmin");
      } else return this.$t("User");
    },
    formatUserType(user) {
      let result = []
      if (user.types) {
        for (let [key, value] of Object.entries(user.types)) {
          if (value.value) {
            result.push(this.$t(key))
          }
        } 
        return result.join(", ")
      } else {return "-"}
    },
    formatInvoiceType(type) {
      switch (type) {
        case "rental_mediation":
          return this.$t("RentalMediation");
        case "sales_mediation":
          return this.$t("SalesMediation");
        case "representation_of_the_purchasing_customer":
          return this.$t("RepresentationOfThePurchasingCustomer");
        case "consultation":
          return this.$t("Consultation");
        case "referral_fee":
          return this.$t("ReferralFee");
        case "appraisal":
          return this.$t("Appraisal");
        case "collaboration":
          return this.$t("Collaboration");
        case "other":
          return this.$t("Other");
        default:
          return "-";
      }
    },
    openBrokerDetailPanel(event, person) {
      let position = {
        left: null,
        top: null,
      };
      position.left = event.pageX + "px";
      position.top = event.clientY + "px";
      this.position = position;
      this.clickedBroker = person;
      this.brokerDetailOpened = true;
    },
    closeBrokerDetailPanel() {
      this.brokerDetailOpened = false;
    },
    formatLoginDate(date) {
      return moment(date).format("HH:mm DD.MM.YYYY");
    },
    async setupTable() {
      this.datasetCopy = this.dataset.slice(0);
      this.originalDataSet = [...this.datasetCopy];
      this.sortedDataSet = [...this.datasetCopy];
      this.originalArr = [...this.fields];
      this.sortedFields = [...this.fields];
      let temporaryCountersArray = [];
      this.fields.forEach((element) => {
        let counterValue = element.jsonValue;
        let sortOrder = 1;
        if (
            this.selectedSort &&
            element.jsonValue === this.selectedSort.sortField &&
            this.selectedSort.sortOrder
        ) {
          sortOrder =
              this.selectedSort.sortOrder === 3
                  ? 1
                  : this.selectedSort.sortOrder + 1;
        }
        let sortCounter = {};
        sortCounter[counterValue] = sortOrder;
        temporaryCountersArray.push(sortCounter);
      });
      this.sortCounters = temporaryCountersArray;
      this.dataLoaded = true;
    },
    handleSearch() {
      this.datasetCopy = this.dataset.slice(0);
      this.sortedDataSet = [...this.datasetCopy];
    },
    formatListingType(e) {
      if (e == "sale") {
        return this.$t("Sale");
      } else if (e == "rent") {
        return this.$t("Rent");
      } else if (e == "short_term_rent") {
        return this.$t("ShortTermRent");
      } else {
        return "-";
      }
    },
    formatEstateType(e) {
      if (e == "apartment") {
        return this.$t("Apartment");
      } else if (e == "house") {
        return this.$t("House");
      } else if (e == "house_part") {
        return this.$t("HousePart");
      } else if (e == "land") {
        return this.$t("LandPlot");
      } else if (e == "cottage") {
        return this.$t("SummerHouse");
      } else if (e == "garage") {
        return this.$t("Garage");
      } else {
        return "-";
      }
    },
    /*  formatBrokerageFee(object) {
      if (object.brokerageFeeType === "percentOfPrice") {
        if (!object.dealValue) {
          if (object.minimumFee) {
            return object.minimumFee + " €";
          } else {
            return "-";
          }
        } else {
          let percent = parseFloat(object.brokerFeePercentage) / 100;
          let answer = parseFloat(object.dealValue) * percent;
          if (object.minimumFee) {
            if (answer < parseFloat(object.minimumFee)) {
              answer = object.minimumFee;
            }
          }
          if (Number.isSafeInteger(parseFloat(answer))) {
            return parseInt(answer) + " €";
          } else {
            return parseFloat(answer).toFixed(2) + " €";
          }
        }
      } else if (object.brokerageFeeType === "agreedPrice") {
        if (object.brokerageFee) {
          if (Number.isSafeInteger(parseFloat(object.brokerageFee))) {
            return parseInt(object.brokerageFee) + " €";
          } else {
            return parseFloat(object.brokerageFee).toFixed(2) + " €";
          }
        } else {
          return "-";
        }
      }
    }, */
    numberOfInterests(object) {
      if (
          !object.interests ||
          object.interests == null ||
          object.interests == "[]" ||
          object.interests.length == 0
      ) {
        return "-";
      } else {
        return object.interests.length;
      }
    },
    numberOfEmails(object) {
      if (object.emails.length > 1) {
        return "+" + (object.emails.length - 1);
      }
    },
    numberOfPhones(object) {
      if (object.phoneNumbers.length > 1) {
        return "+" + (object.phoneNumbers.length - 1);
      }
    },
    numberOfOrganizations(object) {
      if (object.organizations.length > 1) {
        return "+" + (object.organizations.length - 1);
      }
    },
    collapseBoard(index, object) {
      let boardAlreadyOpened = false;
      let listingRowIndex = index; //listing row index
      let kanbanIndex = "kanban" + (index + 1); //next table row index from clicked listing
      let arrowUp = document.getElementById(index + "up");
      let arrowDown = document.getElementById(index + "down");
      if (document.getElementById(kanbanIndex)) {
        boardAlreadyOpened = true;
      }
      if (!boardAlreadyOpened) {
        let tableBody = document.getElementById("table-body");
        let newKanbanRow = tableBody.insertRow(listingRowIndex + 1);
        newKanbanRow.id = "kanbanRow" + (index + 1);
        newKanbanRow.style.display = "flex";
        let newCell = newKanbanRow.insertCell(0);
        newCell.innerHTML = `<LeadsKanban :id='${kanbanIndex}' style='width: 100%' :listing='${object}' />`;
      } else {
        let clickedKanbanRow = document.getElementById(
            "kanbanRow" + (index + 1)
        );
        if (clickedKanbanRow.style.display !== "flex") {
          clickedKanbanRow.style.display = "flex";
          arrowUp.style.display = "block";
          arrowDown.style.display = "none";
        } else {
          clickedKanbanRow.style.display = "none";
          arrowUp.style.display = "none";
          arrowDown.style.display = "block";
        }
      }
    },
    async saveEditedObject() {
      if (event.target.value === "") {
        this.objectEdited[this.editedField] = 0;
      }
      if (this.listingView && !this.adminMode) {
        let objectId = this.objectId;
        let unit = this.objectEdited;
        let editedField = this.editedField;
        let saveBody = {objectId, unit, editedField};
        await axios.post(`/api/project/unit/edit/${this.dealId}`, saveBody);
      }
      if (this.listingView && this.adminMode) {
        let admin = this.objectEdited;
        let editedField = this.editedField;
        let saveBody = {admin, editedField};
        await axios.post(`/api/admin/limits/edit/${admin._id}`, saveBody);
        this.$emit("totalListingCounter", saveBody);
      }
    },
    columnAction(index, field, reSort, reset) {
      if (
          this.customerView ||
          this.scorecardView ||
          this.transactionView ||
          this.adminView ||
          this.invoicesView ||
          this.organizationsView
      ) {
        if (index === "closeAll") {
          this.editableIndex = null;
        }
        //field check
        if (field) {
          this.lastSortedField = field;
          let sortingStep = null;
          let sortingCounterIndex = null;

          this.sortCounters.forEach((counter, index) => {
            Object.keys(counter).forEach((key) => {
              if (key === field.jsonValue) {
                sortingCounterIndex = index;
                sortingStep = counter[key];
              } else if (key !== field.jsonValue) {
                counter[key] = 1;
              }
            });
          });
          this.lastSortedStep = sortingStep;
          if (reSort) {
            sortingStep = this.lastSortedStep - 1;
          }
          if (reset) {
            sortingStep = 3;
          }

          if (
              typeof field.jsonValue === "number" ||
              field.jsonValue === "dealValue" ||
              field.jsonValue === "brokerageFee"
          ) {
            if (sortingStep == 3) {
              this.sortedDataSet = [...this.originalDataSet];
              if (!reSort) {
                this.sortCounters[sortingCounterIndex][field.jsonValue] = 1;
              }
            } else if (sortingStep == 1) {
              this.sortedDataSet.sort((a, b) => {
                /*  if (!a[field.jsonValue]) return 1;
                if (!b[field.jsonValue]) return -1; */
                return a[field.jsonValue] - b[field.jsonValue];
              });
              if (!reSort) {
                this.sortCounters[sortingCounterIndex][field.jsonValue] += 1;
              }
            } else if (sortingStep == 2) {
              this.sortedDataSet.sort((a, b) => {
                //if (!b[field.jsonValue]) return -1;
                return b[field.jsonValue] - a[field.jsonValue];
              });
              if (!reSort) {
                this.sortCounters[sortingCounterIndex][field.jsonValue] += 1;
              }
            }
          } else {
            if (field.jsonValue === "group") {
              if (sortingStep === 3) {
                this.sortedDataSet = [...this.originalDataSet];
                this.personWithoutGroup = [];
                if (!reSort) {
                  this.sortCounters[sortingCounterIndex]["group"] = 1;
                }
              } else if (sortingStep === 1) {
                this.personWithoutGroup = [];

                this.sortedDataSet.forEach((person) => {
                  if (person.groups.length === 0) {
                    this.personWithoutGroup.push(person);
                  }
                });
                this.sortedDataSet = this.sortedDataSet.filter((person) => {
                  if (person.groups.length) {
                    return person.groups.length > 0;
                  }
                });
                this.sortedDataSet.sort((a, b) =>
                    !this.formatGroupName(a)
                        ? 1
                        : !this.formatGroupName(b)
                            ? -1
                            : this.formatGroupName(a) > this.formatGroupName(b)
                                ? 1
                                : this.formatGroupName(b) > this.formatGroupName(a)
                                    ? -1
                                    : 0
                );
                this.personWithoutGroup.forEach((person) => {
                  this.sortedDataSet.push(person);
                });

                if (!reSort) {
                  this.sortCounters[sortingCounterIndex]["group"] += 1;
                }
              } else if (sortingStep === 2) {
                this.sortedDataSet = this.sortedDataSet.filter((person) => {
                  if (person.groups.length) {
                    return person.groups.length > 0;
                  }
                });
                this.sortedDataSet.sort((a, b) =>
                    !this.formatGroupName(b)
                        ? -1
                        : this.formatGroupName(a) < this.formatGroupName(b)
                            ? 1
                            : this.formatGroupName(b) < this.formatGroupName(a)
                                ? -1
                                : 0
                );
                this.personWithoutGroup.forEach((person) => {
                  this.sortedDataSet.unshift(person);
                });
                if (!reSort) {
                  this.sortCounters[sortingCounterIndex]["group"] += 1;
                }
              }
            } else if (field.jsonValue === "projects") {
              if (sortingStep == 3) {
                this.sortedDataSet = [...this.originalDataSet];
                if (!reSort) {
                  this.sortCounters[sortingCounterIndex][field.jsonValue] = 1;
                }
              } else if (sortingStep == 1) {
                // this.sortedDataSet.sort((a, b) => {
                //   let aActiveValue = a.projectCounts.active;
                //   let aArchivedValue = a.projectCounts.archived;
                //   let aTotal = aActiveValue + aArchivedValue;
                //
                //   let bActiveValue = b.projectCounts.active;
                //   let bArchivedValue = b.projectCounts.archived;
                //   let bTotal = bActiveValue + bArchivedValue;
                //
                //   if (!aTotal) return -1;
                //   if (!bTotal) return 1;
                //   return aTotal > bTotal ? 1 : -1;
                // });
                if (!reSort) {
                  this.sortCounters[sortingCounterIndex][field.jsonValue] += 1;
                }
              } else if (sortingStep == 2) {
                // this.sortedDataSet.sort((a, b) => {
                //   let aActiveValue = a.projectCounts.active;
                //   let aArchivedValue = a.projectCounts.archived;
                //   let aTotal = aActiveValue + aArchivedValue;
                //
                //   let bActiveValue = b.projectCounts.active;
                //   let bArchivedValue = b.projectCounts.archived;
                //   let bTotal = bActiveValue + bArchivedValue;
                //
                //   if (!bTotal) return -1;
                //   return aTotal < bTotal ? 1 : -1;
                // });
                if (!reSort) {
                  this.sortCounters[sortingCounterIndex][field.jsonValue] += 1;
                }
              }
              if (this.customerView) {
                let data = {
                  sortField: "projects",
                  sortOrder: sortingStep,
                };
                return this.$emit("sortCustomers", data);
              }
            } else if (
                field.jsonValue === "creator" ||
                field.jsonValue === "broker"
            ) {
              if (sortingStep == 3) {
                this.sortedDataSet = [...this.originalDataSet];
                if (!reSort) {
                  this.sortCounters[sortingCounterIndex][field.jsonValue] = 1;
                }
              } else if (sortingStep == 1) {
                if (field.jsonValue === 'creator') {
                  this.sortedDataSet.forEach((person) => {
                    let answer = this.getOwner(person.access.users);
                    person.creator.name = answer;
                });
                }
                this.sortedDataSet.sort((a, b) => {
                  let aValue = a[field.jsonValue]
                      ? a[field.jsonValue].name
                      : a["creator"].name;
                  let bValue = b[field.jsonValue]
                      ? b[field.jsonValue].name
                      : b["creator"].name;
                  if (typeof aValue === "string") {
                    aValue = aValue.toLowerCase();
                  }
                  if (typeof bValue === "string") {
                    bValue = bValue.toLowerCase();
                  }
                  if (!aValue) return -1;
                  if (!bValue) return 1;
                  return aValue > bValue ? 1 : -1;
                });
                if (!reSort) {
                  this.sortCounters[sortingCounterIndex][field.jsonValue] += 1;
                }
              } else if (sortingStep == 2) {
                this.sortedDataSet.sort((a, b) => {
                  let aValue = a[field.jsonValue]
                      ? a[field.jsonValue].name
                      : a["creator"].name;
                  let bValue = b[field.jsonValue]
                      ? b[field.jsonValue].name
                      : b["creator"].name;
                  if (typeof aValue === "string") {
                    aValue = aValue.toLowerCase();
                  }
                  if (typeof bValue === "string") {
                    bValue = bValue.toLowerCase();
                  }
                  if (!bValue) return -1;
                  return aValue < bValue ? 1 : -1;
                });
                if (!reSort) {
                  this.sortCounters[sortingCounterIndex][field.jsonValue] += 1;
                }
              }
            } else if (field.jsonValue === "accessLevel") {
              if (sortingStep == 3) {
                this.sortedDataSet = [...this.originalDataSet];
                if (!reSort) {
                  this.sortCounters[sortingCounterIndex][field.jsonValue] = 1;
                }
              } else if (sortingStep == 1) {
                this.sortedDataSet.forEach((person) => {
                  let answer = this.formatRole(person);
                  person.role = answer;
                });
                this.sortedDataSet.sort((a, b) =>
                    !a.role
                        ? 1
                        : !b.role
                            ? -1
                            : a.role > b.role
                                ? 1
                                : b.role > a.role
                                    ? -1
                                    : 0
                );
                if (!reSort) {
                  this.sortCounters[sortingCounterIndex][field.jsonValue] += 1;
                }
              } else if (sortingStep == 2) {
                this.sortedDataSet.sort((a, b) => {
                  let aValue = a[field.jsonValue];
                  let bValue = b[field.jsonValue];
                  if (typeof aValue === "string") {
                    aValue = aValue.toLowerCase();
                  }
                  if (typeof bValue === "string") {
                    bValue = bValue.toLowerCase();
                  }
                  if (!bValue) return -1;
                  return aValue < bValue ? 1 : -1;
                });
                if (!reSort) {
                  this.sortCounters[sortingCounterIndex][field.jsonValue] += 1;
                }
              }
            } else if (field.jsonValue === "types") {
              if (sortingStep == 3) {
                this.sortedDataSet = [...this.originalDataSet];
                if (!reSort) {
                  this.sortCounters[sortingCounterIndex][field.jsonValue] = 1;
                }
              } else if (sortingStep == 1) {
                this.sortedDataSet.forEach((person) => {
                  let answer = this.formatUserType(person);
                  person.type = answer.split(",")[0];
                });
                this.sortedDataSet.sort((a, b) =>
                    !a.type
                        ? 1
                        : !b.type
                            ? -1
                            : a.type > b.type
                                ? 1
                                : b.type > a.type
                                    ? -1
                                    : 0
                );
                if (!reSort) {
                  this.sortCounters[sortingCounterIndex][field.jsonValue] += 1;
                }
              } else if (sortingStep == 2) {
                this.sortedDataSet.sort((a, b) => {
                  let aValue = a[field.jsonValue];
                  let bValue = b[field.jsonValue];
                  if (typeof aValue === "string") {
                    aValue = aValue.toLowerCase();
                  }
                  if (typeof bValue === "string") {
                    bValue = bValue.toLowerCase();
                  }
                  if (!bValue) return -1;
                  return aValue < bValue ? 1 : -1;
                });
                if (!reSort) {
                  this.sortCounters[sortingCounterIndex][field.jsonValue] += 1;
                }
              }
            } else if (field.jsonValue === "tags") {
              if (sortingStep === 3) {
                this.sortedDataSet = [...this.originalDataSet];
                if (!reSort) {
                  this.sortCounters[sortingCounterIndex]["tags"] = 1;
                }
              } else if (sortingStep === 1) {
                const arr = [];
                const arr2 = [];

                this.sortedDataSet.forEach((dataset) => {
                  //check if any tag inside tag object is true and
                  //and no same elements exists in arrays (arr1 and arr2)
                  if (
                      Object.values(dataset.tags).find(
                          (tag) => tag["active"] === true
                      ) &&
                      !arr.some((el) => el._id === dataset._id) &&
                      !arr2.some((el) => el._id === dataset._id)
                  ) {
                    arr.push(dataset);
                  } else {
                    arr2.push(dataset);
                  }
                });

                // sort by priority
                arr.sort((a, b) => {
                  let aHighest = 0;
                  let bHighest = 0;
                  let aTotal = 0;
                  let bTotal = 0;
                  Object.values(a.tags).forEach((el) => {
                    if (el.active && el.priority > aHighest) {
                      aHighest = el.priority;
                    }
                    if (el.active) {
                      aTotal += el.priority;
                    }
                  });
                  Object.values(b.tags).forEach((el) => {
                    if (el.active && el.priority > bHighest) {
                      bHighest = el.priority;
                    }
                    if (el.active) {
                      bTotal += el.priority;
                    }
                  });
                  if (aHighest === bHighest) {
                    return bTotal - aTotal;
                  } else {
                    return bHighest - aHighest;
                  }
                });
                // combine two arrays
                const combined = arr.concat(arr2);
                this.sortedDataSet = combined;

                if (!reSort) {
                  this.sortCounters[sortingCounterIndex]["tags"] += 1;
                }
              } else if (sortingStep === 2) {
                this.sortedDataSet = this.sortedDataSet.reverse();
                if (!reSort) {
                  this.sortCounters[sortingCounterIndex]["tags"] += 1;
                }
              }
              if (this.customerView) {
                let data = {
                  sortField: "tags",
                  sortOrder: sortingStep,
                };
                this.$emit("sortCustomers", data);
              }
            } else {
              if (sortingStep == 3) {
                if (!this.customerView) {
                  this.sortedDataSet = [...this.dataset];
                }
                if (!reSort) {
                  this.sortCounters[sortingCounterIndex][field.jsonValue] = 1;
                }
              } else if (sortingStep == 1) {
                if (!this.customerView) {
                  this.sortedDataSet.sort((a, b) => {
                    let aValue = a[field.jsonValue];
                    let bValue = b[field.jsonValue];
                    if (typeof aValue === "string") {
                      aValue = aValue.toLowerCase();
                    }
                    if (typeof bValue === "string") {
                      bValue = bValue.toLowerCase();
                    }
                    if (!aValue) return -1;
                    if (!bValue) return 1;
                    return aValue > bValue ? 1 : -1;
                  });
                }
                if (!reSort) {
                  this.sortCounters[sortingCounterIndex][field.jsonValue] += 1;
                }
              } else if (sortingStep == 2) {
                if (!this.customerView) {
                  this.sortedDataSet.sort((a, b) => {
                    let aValue = a[field.jsonValue];
                    let bValue = b[field.jsonValue];
                    if (typeof aValue === "string") {
                      aValue = aValue.toLowerCase();
                    }
                    if (typeof bValue === "string") {
                      bValue = bValue.toLowerCase();
                    }
                    if (!bValue) return -1;
                    if (!aValue) return 1;
                    return aValue < bValue ? 1 : -1;
                  });
                }
                if (!reSort) {
                  this.sortCounters[sortingCounterIndex][field.jsonValue] += 1;
                }
              }
              if (this.customerView) {
                let data = {
                  sortField: field.jsonValue,
                  sortOrder: sortingStep,
                };
                this.$emit("sortCustomers", data);
              } else if (this.organizationsView) {
                let data = {
                  sortField: field.jsonValue,
                  sortOrder: sortingStep,
                };
                this.$emit("sortOrganizations", data);
              }
            }
          }
        }
      }
      if (this.listingView) {
        if (index === "closeAll") {
          this.editableIndex = null;
        } else if (
            this.editableIndex !== null &&
            index !== this.editableIndex
        ) {
          //check if edit mode already open and new click not on the same column
          this.editableIndex = null;
        } else {
          this.editableIndex = index;
        }
      }
    },
    openCustomer(object, regCode, org) {
      if (this.customerView || this.adminView) {
        this.setClientSidepanel();
        if (/^\d+$/.test(regCode)) {
          this.$router
              .push({
                name: "KliendiregisterDetail",
                params: {
                  id: object.customerId,
                  openedOrganizationRegCode: regCode,
                },
              })
              .then(() => {
              });
        } else {
          this.$router
              .push({
                name: "KliendiregisterDetail",
                params: {
                  id: object.customerId,
                  openedOrganizationRegCode: org,
                },
              })
              .then(() => {
              });
        }
      }
    },
    columnClick(index, field, object, event) {
      this.clickedObject = object;
      if (this.listingView) {
        if (
            field.jsonValue === "listings" ||
            field.jsonValue === "activeListings"
        ) {
          this.editableIndex = null;
          return;
        } else if (
            field.jsonValue === "firstname" ||
            field.jsonValue === "lastname" ||
            field.jsonValue === "group" ||
            field.jsonValue === "userStatus" ||
            field.jsonValue === "LastLogin" ||
            field.jsonValue === "groups" ||
            field.jsonValue === "accessLevel" ||
            field.jsonValue === "types"
        ) {
          this.$emit("userClicked", object);
          this.editableIndex = null;
          return;
        } else if (index === "closeAll") {
          this.editableIndex = null;
        } else if (
            this.editableIndex !== null &&
            index !== this.editableIndex
        ) {
          //check if edit mode already open and new click not on the same column
          this.editableIndex = null;
        } else {
          this.editableIndex = index;
        }
      } else if (this.scorecardView) {
        this.$emit("scorecardClicked", object.id, field.jsonValue);
      } else if (this.transactionView && field.jsonValue !== "creator") {
        this.$emit("transactionClicked", object);
      } else if (this.transactionView && field.jsonValue === "creator") {
        this.openBrokerDetailPanel(event, object.creator.userId);
      } else if (this.invoicesView && field.jsonValue !== "status") {
        this.$emit("invoiceClicked", object._id || object);
      } else if (this.organizationsView) {
        this.$emit("fieldOnPressed", object._id);
      }
      if ((this.customerView || this.adminView) && !this.adminMode) {
        if (field.jsonValue === "organization" && !object.organizations[0]) {
          this.openCustomer(object);
        } else if (
            field.jsonValue === "organization" &&
            object.organizations[0].reg_code
        ) {
          this.openCustomer(object, object.organizations[0].reg_code);
        } else if (field.jsonValue !== "organization") {
          this.openCustomer(object);
        }
      }
      if (this.transactionView) {
        console.log("TRANSACTION ROUTING HERE::::", object);
      }
    },
    openStatusDropdown(object) {
      let button = document.getElementById("statusLabel" + object._id);
      const rect = button.getBoundingClientRect();
      this.position = {};
      this.position.right = window.innerWidth - rect.right;
      this.position.top = rect.top + window.scrollY - 50;
      this.$emit("openStatusDropdown", object, this.position);
    },
    roundValue(value) {
      let numberValue = Number(value);
      return numberValue.toFixed(this.roundingDigits);
    },
    formatTotal(number) {
      const decimalsArray = number.toString().split(".");
      if (decimalsArray[1] && decimalsArray[1].length >= 2) {
        number = number.toFixed(2);
      }
      return number;
    },
    formatGroupName(userObject) {
      if (userObject.groups.length) {
        let groupName = userObject.groups.find((group) => group.userDefault)?.name
        return groupName || userObject.groups[0].name;
      }
      return this.$t("UnAllocated")
    }
  },
  watch: {
    dataset() {
      this.datasetCopy = this.dataset.slice(0);
      this.originalDataSet = [...this.datasetCopy];
    },
    updateKey() {
      this.handleSearch();
    },
    sortAgain(val) {
      if (!this.customerView || !this.adminView) {
        if (this.lastSortedField && this.lastSortedStep) {
          //3-resort, 4-reset
          if (val === 0) {
            this.columnAction(
                this.sortingCounterIndex,
                this.lastSortedField,
                true,
                true
            );
          } else {
            this.columnAction(
                this.lastSortedIndex,
                this.lastSortedField,
                true,
                false
            );
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../global_variables";

.table-wrapper {
  border-spacing: 0px;
  border-collapse: collapse;
}

th,
td {
  padding: 12px 16px 12px 16px;
}

.column-label {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.tag {
  padding: 4px 8px;
  margin-right: 4px;
  border-radius: 4px;
  background-color: #f4f5f7;
}

.tag:before {
  top: 8px;
  left: 8px;
  width: 8px;
  height: 8px;
  content: "";
  position: absolute;
  border-radius: 50%;
  background-color: #0aaf60;
}

.table-head {
  background: white;
}

.table-head-item {
  white-space: nowrap;
  text-align: left;
  cursor: pointer;
  height: 40px !important;
}

.table-head-item:first-child {
  border-top-left-radius: 8px;
}

.table-head-item:last-child {
  border-top-right-radius: 8px;
}

::v-deep .copy-button {
  visibility: hidden !important;
}

.table-body-item {
  //border-bottom: 1pt solid #e6e8ec;
  cursor: pointer;

  &:hover {
    ::v-deep .copy-button {
      visibility: visible !important;
    }
  }
}

.table-body-row:last-child {
  .table-body-item:first-child {
    border-bottom-left-radius: 8px;
  }

  .table-body-item:last-child {
    border-bottom-right-radius: 8px;
  }
}

.gray {
  background: #f4f5f7;
}

.tags {
  p {
    margin-bottom: 0 !important;
    font-size: 12px;
    line-height: 16px;
  }

  .active {
    width: 8px;
    height: 8px;
    margin-right: 8px;
    background-image: url("../../assets/images/green-circle.svg");
  }

  .tag-wrapper {
    align-items: center;
  }
}

.tag-wrapper {
  align-items: center;
}

.tag-container-table {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 8px;
}

.white-tag-text {
  color: white;
}

.listing-item {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  overflow: hidden;
  max-width: 85%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.listing-item-no-ellipsis {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  overflow: hidden;
}

.listing-desc {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #75787a;
}

.underlined {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.booked {
  background-color: #ff5c01;
}

.free {
  background-color: #0aaf60;
}

.rented {
  background-color: #ff1e24;
}

.statusLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px;
  color: white;
  max-width: fit-content;
  border-radius: 4px;
  cursor: pointer;
}

.output-row {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.table-body-row {
  background-color: white;
  border-top: 1px solid #e6e8ec;

  &:hover {
    background-color: $light-grey;
  }

  &:focus {
    background-color: black;
  }
}

.active-row {
  background-color: $grey900;
}

.recently-added {
  background-color: #c3c4c6; //grey800
}

.separator-row-main {
  background: #e6e8ec;
}

.separator-row {
  height: 2px !important;
  padding: 0 !important;
}

.normal-field {
  word-break: break-word;
  overflow: hidden;
  cursor: pointer;
  max-width: 100%;
}

.additional-info {
  background-color: #f4f5f7;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: row;
  width: 26px;
  height: 20px;
  border-radius: 4px;
}

.visible {
  display: initial;
}

.invisible {
  display: none;
}

.up {
  display: initial;
}

.down {
  display: initial;
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.hidden {
  display: none;
}

.output-row {
  padding: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 0px !important;
  /* width: 181px;
  height: 96px; */
}

.outputs-modal {
  &:hover {
    ::v-deep .copy-button {
      margin-left: 8px;
      visibility: visible !important;
    }
  }

  background-color: white;
}

.org-link {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

::v-deep.v-menu__content {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.03),
  0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.normal-weight {
  font-weight: 400;
}

.tag-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  padding-right: 2.5px;
  padding-left: 2.5px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.bold-weight {
  font-weight: 700;
}

.paid-label {
  font-weight: 500;
  color: black;
  background-color: #b4e7cf;
  border-radius: 5px;
  padding: 2px 4px;
}

.not-paid-label {
  font-weight: 500;
  color: black;
  background-color: #ffb4b6;
  border-radius: 5px;
  padding: 2px 4px;
  width: fit-content;
}
</style>
